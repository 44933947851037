import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/Layout";
import PostTopSection from "../components/blog/PostTopSection";
import styled from "styled-components";
import CodeBlock from "../components/blog/CodeBlock";
import { MDXProvider } from "@mdx-js/react";
import TableWrapper from "../components/blog/TableWrapper";
import axios from "axios";
import { Helmet } from "react-helmet";

const components = {
	pre: CodeBlock,
	table: TableWrapper,
};

const PostLayout = ({ location, data }) => {
	const [views, setViews] = useState(null);
	const backToLink =
		"state" in location
			? location.state !== null
				? "from" in location.state
					? location.state.from !== null
						? location.state.from
						: "/blog"
					: "/blog"
				: "/blog"
			: "/blog";
	const postViewAdd = () => {
		axios
			.get("/api/post/views")
			.then((res) => setViews(res.data.views))
			.catch(() => {
				// Disable views count if back-end returns an error
				setViews(null);
			});
	};
	useEffect(() => {
		postViewAdd();
	}, []);
	return (
		<Layout pageTitle={`${data.mdx.frontmatter.title}`}>
			<Helmet>
				<meta property="og:type" content="article" />
				<meta property="og:title" content={data.mdx.frontmatter.title} />
				{data.mdx.frontmatter.main && (
					<meta
						property="og:image"
						content={`${data.site.siteMetadata.siteUrl}${data.mdx.frontmatter.main.publicURL}`}
					/>
				)}
				<meta property="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={data.mdx.frontmatter.title} />
				{data.mdx.frontmatter.main && (
					<meta
						name="twitter:image"
						content={`${data.site.siteMetadata.siteUrl}${data.mdx.frontmatter.main.publicURL}`}
					/>
				)}
			</Helmet>
			<PostContainer>
				<PostTopSection backTo={backToLink} data={data} views={views} />
				<article className="post-content">
					<MDXProvider components={components}>
						<MDXRenderer>{data.mdx.body}</MDXRenderer>
					</MDXProvider>
				</article>
			</PostContainer>
		</Layout>
	);
};

const PostContainer = styled.div`
	width: 50%;
	margin: auto;
	.post-content {
		margin-bottom: 5rem;
		a {
			color: var(--fg);
			font-weight: 500;
			text-decoration: none;
			position: relative;
			:before {
				content: "";
				width: 100%;
				height: 3px;
				position: absolute;
				top: 100%;
				left: 0;
				opacity: 0.3;
				background: var(--accent);
				border-radius: 5px;
			}
			:after {
				content: "";
				width: 0;
				height: 3px;
				position: absolute;
				top: 100%;
				left: 0;
				background: var(--accent);
				transition: width 150ms;
				border-radius: 5px;
			}
			:hover {
				:after {
					width: 100%;
				}
			}
		}
		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		code {
			margin-block: 1rem;
		}
		p,
		a,
		li,
		code,
		td,
		span,
		table {
			font-size: 1.33rem;
		}
		hr {
			border: none;
			height: 3px;
			border-radius: 5px;
			background: var(--accent);
			margin-block: 1rem;
		}
		blockquote {
			margin-block: 1rem;
			position: relative;
			padding: 1rem;
			background: var(--codeBlockBg);
			border-radius: 5px;
			overflow: auto;
			blockquote {
				margin-top: 1rem;
				margin-bottom: 0;
			}
			p {
				display: inline;
			}
			:before {
				top: 0;
				left: 0;
				content: "";
				width: 3px;
				height: 100%;
				position: absolute;
				background: var(--accent);
			}
		}
		ul,
		ol {
			padding-left: 3rem;
			margin-block: 1rem;
			ul,
			p {
				margin: 0;
			}
		}
		pre {
			border-radius: 5px;
			overflow: auto;
			font-family: "JetBrains Mono", monospace;
			margin-block: 1rem;
		}
		p {
			code {
				background: hsl(213, 13%, 19%);
				color: #abb2bf;
				border-radius: 5px;
				padding: 0.1rem 0.4rem;
				font-family: "JetBrains Mono", monospace;
			}
		}
		table {
			width: 100%;
			border-collapse: collapse;
			th,
			td {
				border: 1px solid rgba(51, 53, 52, 0.7);
				padding: 0.3rem;
			}
			th {
				background-color: var(--hoverBg);
			}
		}
		img {
			width: 100%;
			border-radius: 5px;
			margin-block: 1rem;
			height: auto;
		}
		p {
			img {
				margin-top: 0;
				margin-bottom: 1rem;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
	@media (max-width: 1300px) {
		width: 70%;
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`;

export const query = graphql`
	query ($slug: String!) {
		mdx(slug: { eq: $slug }) {
			frontmatter {
				created(formatString: "DD MMMM YYYY")
				title
				updated(formatString: "DD MMMM YYYY")
				main {
					publicURL
				}
			}
			body
			timeToRead
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;

export default PostLayout;
