import React from "react";
import styled from "styled-components";

const TableWrapper = (props) => {
	return (
		<StyledTableWrapper>
			<table {...props}></table>
		</StyledTableWrapper>
	);
};

const StyledTableWrapper = styled.div`
	overflow-x: auto;
	margin-block: 1rem;
`;

export default TableWrapper;
