// @flow
// Converted automatically using ./tools/themeFromVsCode

var theme = {
	plain: {
		color: "#abb2bf",
		backgroundColor: "#282c34",
	},
	styles: [
		{
			types: ["punctuation", "constant", "deleted", "tag"],
			style: {
				color: "rgb(224, 108, 117)",
			},
		},
		{
			types: ["variable", "keyword", "selector"],
			style: {
				color: "rgb(198, 120, 221)",
			},
		},
		{
			types: ["builtin", "changed", "namespace", "class-name"],
			style: {
				color: "rgb(229, 192, 123)",
			},
		},
		{
			types: ["operator"],
			style: {
				color: "rgb(171, 178, 191)",
			},
		},
		{
			types: ["inserted", "string"],
			style: {
				color: "rgb(152, 195, 121)",
			},
		},
		{
			types: ["char", "number", "attr-name"],
			style: {
				color: "rgb(209, 154, 102)",
			},
		},
		{
			types: ["function"],
			style: {
				color: "rgb(97, 175, 239)",
			},
		},
		{
			types: ["symbol"],
			style: {
				color: "rgb(86, 182, 194)",
			},
		},
		{
			types: ["comment"],
			style: {
				fontStyle: "italic",
			},
		},
	],
};

module.exports = theme;
