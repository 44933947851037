import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "../../themes/codeBlock/oneDarkPro";

const CodeBlock = (props) => {
	const className = props.children.props.className || "";
	const matches = className.match(/language-(?<lang>.*)/);

	return (
		<Highlight
			{...defaultProps}
			code={props.children.props.children.trim()}
			language={
				matches && matches.groups && matches.groups.lang
					? matches.groups.lang
					: ""
			}
			theme={theme}
		>
			{({ className, style, tokens, getLineProps, getTokenProps }) => (
				<pre
					className={className}
					style={{
						...style,
						background: "hsl(213, 13%, 19%)",
						padding: "1rem",
					}}
				>
					{tokens.map((line, i) => (
						<div key={i} {...getLineProps({ line, key: i })}>
							{line.map((token, key) => (
								<span
									style={{ color: "var(--fg)" }}
									key={key}
									{...getTokenProps({ token, key })}
								/>
							))}
						</div>
					))}
				</pre>
			)}
		</Highlight>
	);
};

export default CodeBlock;
