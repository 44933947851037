import React from "react";
import BackButton from "../BackButton";
import styled from "styled-components";

const PostTopSection = ({ backTo, data, views }) => {
	return (
		<StyledPostTopSection>
			<div className="back-btw-wrapper">
				<BackButton to={backTo} />
				{views !== null ? (
					<p>
						Views <span>{views}</span>
					</p>
				) : (
					""
				)}
			</div>
			<h1>{data.mdx.frontmatter.title}</h1>
			<div className="post-info">
				<p>
					Published {data.mdx.frontmatter.created} &#8226; {data.mdx.timeToRead}{" "}
					{parseInt(data.mdx.timeToRead) > 1 ? "Mins" : "Min"}
				</p>
				{data.mdx.frontmatter.updated && (
					<p>Updated {data.mdx.frontmatter.updated}</p>
				)}
			</div>
			<div className="dots">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</StyledPostTopSection>
	);
};

const StyledPostTopSection = styled.div`
	.back-btw-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			color: var(--gray);
			span {
				font-weight: 500;
			}
		}
	}
	.post-info {
		display: flex;
		justify-content: space-between;
		color: var(--gray);
	}
	.dots {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-block: 2rem;
		width: 100%;
		gap: 3rem;
		div {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			cursor: pointer;
			width: 50px;
			height: 25px;
			:after {
				transition: opacity 0.15s, transform 0.5s;
				content: "";
				position: absolute;
				width: 20px;
				height: 20px;
				background: var(--accent);
				border-radius: 50%;
				opacity: 0.3;
			}
			&:hover {
				:after {
					opacity: 0.5;
					transition: opacity 0.2s, transform 0.2s;
					transform: translateY(-15px) scale(1.2);
				}
			}
			&:active {
				:after {
					opacity: 1;
				}
			}
		}
	}
	@media (max-width: 800px) {
		.post-info {
			flex-direction: column;
		}
		.dots {
			div {
				width: 25px;
			}
		}
	}
`;

export default PostTopSection;
